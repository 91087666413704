// FormContext.jsx
import React, { createContext, useContext, useState } from 'react';

const FormContext = createContext();

export function FormProvider({ children }) {
  const [formData, setFormData] = useState({
    region: '',
    typeOfScan: 'blackandwhite',
    Ceiling: "",
    purpose:"",
    workingHours: null,
    area: { value: "", unit: "sqft" },
    floor: "1",
    buildingType: "",
    mail: "",
    name: "",
    company: "",
    country: "",
    phone: "",
    state: "",
    dateRange: { from: null, to: null } // Se establece dateRange como no requerido
  });
console.log(formData)
  return (
    <FormContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormContext.Provider>
  );
}

export function useForm() {
  return useContext(FormContext);
}
