import React from "react";
import { Divider } from '@tremor/react';

function CeilingInfo() {
  return (
    <div className="p-8 rounded-md ">
      <h2 className="text-3xl font-bold mb-6 text-gray-800 dark:text-gray-200">
        Ceiling Information
      </h2>
      <Divider className="dark:text-slate-400">Below Ceiling</Divider>

      <p className="text-gray-700 dark:text-gray-300 mb-4">
        <strong>Below ceiling</strong> refers to scanning areas that are underneath or within the space directly below the ceiling. This may include lower levels, ceilings of rooms, or any areas that are below the main ceiling surface.
      </p>
      <Divider className="dark:text-slate-400">Above Ceiling</Divider>

      <p className="text-gray-700 dark:text-gray-300 mb-4 mt-4">
        <strong>Above ceiling</strong> refers to scanning areas that are above the main ceiling surface. This typically involves accessing and scanning the space between the ceiling and the upper structural elements, such as in ceiling voids or above suspended ceilings.
      </p>
 
    </div>
  );
}

export default CeilingInfo;
