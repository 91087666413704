import React, { useState, useEffect, useRef } from 'react';
import { useForm } from '../FormContext/FormContext'; // Importa el contexto
import { Card, Divider } from '@tremor/react'; // Importa el componente Card de Tremor
import { motion } from "framer-motion";

// Importación de imágenes
import Healthcare from "../../../../assets/img/healthcare.avif";
import School from "../../../../assets/img/school.avif";
import Office from "../../../../assets/img/office.avif";
import Commercial from "../../../../assets/img/Commercial.avif";
import Stadium from "../../../../assets/img/stadium.avif";
import Other from "../../../../assets/img/other.avif";
import Residential from "../../../../assets/img/Residential.avif";
import Infrastructure from "../../../../assets/img/Infrastructure.avif";
import IndustrialFactory from "../../../../assets/img/Industrial.avif";

function Building(props) {
  const { formData, setFormData } = useForm(); // Obtiene el contexto

  const buildingTypes = [
    { id: "Healthcare", name: "Healthcare", image: Healthcare }, // Placeholder image for Healthcare
    { id: "Education", name: "Education", image: School }, // Placeholder image for Education
    { id: "Government", name: "Government", image: School }, // Placeholder image for Government
    { id: "Office", name: "Office", image: Office }, // Placeholder image for Office
    { id: "Commercial", name: "Commercial", image: Commercial }, // Placeholder image for Commercial
    { id: "IndustrialFactory", name: "Industrial and Factory", image: IndustrialFactory }, // Placeholder image for Industrial and Factory
    { id: "Infrastructure", name: "Infrastructure", image: Infrastructure }, // Placeholder image for Infrastructure
    { id: "Residential", name: "Residential", image: Residential }, // Placeholder image for Residential
    { id: "RecreationStadium", name: "Recreation and Stadium", image: Stadium }, // Placeholder image for Recreation and Stadium
    { id: "Others", name: "Others", image: Other } // Placeholder image for Recreation and Stadium

  ];
  

  const [selectedCard, setSelectedCard] = useState(formData.buildingType || null); // Inicializa el estado con el valor del contexto
  const [otherBuildingType, setOtherBuildingType] = useState(formData.otherBuildingType || ''); // Estado para el tipo de edificio "Other"
  const otherInputRef = useRef(null); // Referencia para el input de "Other"
  const [pulseAnimation, setPulseAnimation] = useState(true); // Estado para controlar la animación de pulsación

  function handleBuildingTypeClick(buildingType) {
    // Actualiza el estado local y el contexto con el tipo de edificio seleccionado
    setSelectedCard(buildingType);
    if (buildingType !== "Others") {
      setFormData({ ...formData, buildingType, otherBuildingType: '' });
    } else {
      setFormData({ ...formData, buildingType: '', otherBuildingType });
    }
    setPulseAnimation(buildingType === "Others"); // Solo activa la animación de pulsación si se selecciona "Other"
  }

  function handleOtherBuildingTypeChange(event) {
    const { value } = event.target;
    setOtherBuildingType(value);
    setFormData({ ...formData, buildingType: value, otherBuildingType: value });
    if (value !== '') {
      setPulseAnimation(false); // Desactiva la animación de pulsación cuando hay texto en el input
    }
  }

  useEffect(() => {
    if (selectedCard === "Others" && otherInputRef.current) {
      otherInputRef.current.scrollIntoView({ behavior: 'smooth' });
      otherInputRef.current.focus();
    }
  }, [selectedCard]);

  return (
    <motion.section  
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
    className="relative flex flex-col h-full overflow-hidden lg:bg-none backdrop-blur" id="plan">
      {/* Div contenedor para los fondos */}
      <div className="absolute inset-0 bg-sidebarLight dark:bg-sidebarBlack lg:hidden z-0 bg-cover bg-center backdrop-blur"></div>
      <form className="p-4 flex-grow z-20" name="multi-step-form">
      <motion.h1 
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
      className="text-2xl font-bold z-20 text-center dark:text-slate-300">
          Building type
          </motion.h1>

        <Divider className="dark:text-slate-400 mt-1 max-w-xl z-20">Select the building</Divider>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-4  overflow-y-auto p-5 z-20">
          {buildingTypes.map((building) => (
            <Card
              key={building.id}
              className={`cursor-pointer text-center p-1 bg-gray-100 rounded-lg h-48 ${selectedCard === building.name ? 'border-2 border-violet-500 font-bold' : ''}`}
              id={building.id}
              onClick={() => handleBuildingTypeClick(building.name)}
              decorationColor={selectedCard === building.name ? 'violet' : 'gray'}
            >
              <div className="flex flex-col items-center justify-center h-full">
                <img
                  src={building.image}
                  alt={`${building.name}-icon`}
                  className="rounded-md h-36 w-full object-cover"
                />
                <h3 className="text-lg  dark:text-white">{building.name}</h3>
              </div>
            </Card>
          ))}
        </div>

        {selectedCard === "Others" && (
         <motion.div
         initial={{ opacity: 0, y: 20 }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ duration: 0.5 }}
         className="relative p-4 rounded-lg flex justify-center max-w-md mx-auto backdrop-blur-sm">
            <div className={`absolute inset-0 rounded-lg  ${pulseAnimation ? 'animate-pulse' : ''}`}></div>
            <div className="relative z-10">
              <label htmlFor="otherBuildingType" className="block text-lg font-medium dark:text-slate-300 text-center">
                Please specify the building type:
              </label>
              <input
                type="text"
                id="otherBuildingType"
                ref={otherInputRef}
                value={otherBuildingType}
                onChange={handleOtherBuildingTypeChange}
                className="mt-2 p-2 w-full border rounded-lg dark:bg-gray-800 dark:text-white dark:border-violet-500"
              />
            </div>
            </motion.div>
        )}
      </form>
    </motion.section>
  );
}

export default Building;
