export const validateStep = (formData, step) => {
    switch (step) {
      case "Info":
        if (formData.region === "") {
          return { isValid: false, message: "Please select a region." };
        }else   if (formData.purpose === "") {
          return { isValid: false, message: "Please select a purpose." };
        }
        break;
      case "Building":
        if (formData.buildingType === "") {
          return { isValid: false, message: "Please select a building type." };
        }
        break;
      case "Services":
        if (formData.Ceiling === "") {
          return { isValid: false, message: "Please select a type of scan." };
        }
        console.log(formData)
        if (formData.workingHours === null) {
          return { isValid: false, message: "Please select hours." };
        }
        break;
      case "Color":
        if (formData.typeOfScan === "") {
          return { isValid: false, message: "Please select a black and white option." };
        }
        break;
      case "Floor":
        if (formData.area === "" || formData.area === "1") {
          return { isValid: false, message: "Please select the area." };
        }
        if (formData.floor === "") {
          return { isValid: false, message: "Please select the floors." };
        }
        break;
      default:
        return { isValid: true };
    }
    return { isValid: true };
  };
  