// utils/validateFinalForm.js

// Function to validate the final form
export const validateFinalForm = (formData) => {
    const errors = [];

    // Validate name
    if (!formData.name || formData.name.trim() === "") {
        errors.push("Name is required.");
    }

    // Validate company name
    if (!formData.company || formData.company.trim() === "") {
        errors.push("Company name is required.");
    }

    // Validate email
    if (!formData.mail || formData.mail.trim() === "") {
        errors.push("Email is required.");
    }

    // Validate region
    if (!formData.region || formData.region.trim() === "") {
        errors.push("Region is required.");
    }

    // Validate state if country is United States
    if (formData.country === "United States" && (!formData.state || formData.state.trim() === "")) {
        errors.push("State is required for United States.");
    }

    // Validate area
    if (!formData.area.value || formData.area.value.trim() === "") {
        errors.push("Area is required.");
    }

    // Validate type of scan
    if (!formData.typeOfScan || formData.typeOfScan.trim() === "") {
        errors.push("The type of scan (black and white or color) is required.");
    }

    // Validate ceiling information
    if (!formData.Ceiling || formData.Ceiling.trim() === "") {
        errors.push("Ceiling information is required.");
    }

    // Validate working hours selection
    if (formData.workingHours === null) {
        errors.push("Working Hours selection is required.");
    }

    // Validate floor information
    if (!formData.floor || formData.floor.toString().trim() === "") {
        errors.push("Floor information is required.");
    }

    // Validate building type
    if (!formData.buildingType || formData.buildingType.trim() === "") {
        errors.push("Building Type is required.");
    }

    // Validate phone number
    
    if (!formData.phone  === "") {
        errors.push("Phone number is required.");
    }

    return errors;
};
