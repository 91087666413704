import React, { useState } from "react";
import './ToggleSwitch.css'; // Import the CSS file

function ToggleSwitch({ checked, onChange, id }) {
  const [showPulseEffect, setShowPulseEffect] = useState(true); // State for pulse effect

  return (
    <div className="flex flex-col items-center">
      {/* Hidden checkbox input for accessibility */}
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={(e) => {
          onChange(e);
          setShowPulseEffect(false); // Remove pulse effect after first click
        }}
        className="sr-only"
      />
      <label
        htmlFor={id}
        className={`toggle-switch-label ${
          checked ? 'toggle-switch-label-light' : 'toggle-switch-label-light'
        } dark:toggle-switch-label-dark ${showPulseEffect ? "pulse-effect" : ""}`}
      >
        {/* Toggle slider */}
        <div
          className={`toggle-switch-slider ${
            checked ? 'toggle-switch-slider-light-checked' : 'toggle-switch-slider-light'
          } dark:toggle-switch-slider-dark ${checked ? 'dark:toggle-switch-slider-dark-checked' : ''}`}
        ></div>
        {/* Toggle labels */}
        <div className="toggle-switch-text">
          <p className={`toggle-switch-text-bw-light ${checked ? 'dark:toggle-switch-text-bw-dark' : ''}`}>B&W</p>
          <p className={`toggle-switch-text-color-light ${checked ? 'dark:toggle-switch-text-color-dark' : ''}`}>Color</p>
        </div>
      </label>
    </div>
  );
}

export default ToggleSwitch;
