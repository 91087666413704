// Internal modules
import React, { useState, useEffect, useRef } from "react";
// Hooks
import { useForm } from "../FormContext/FormContext";
// UI components
import { Divider } from "@tremor/react";
// Styles
import "./Floor.css";
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

function Floor(props) {
  const { formData, setFormData } = useForm();
  const componentNext = props.componentNameNext;
  const componentBack = props.componentNameBack;
  const componentTalkToSales = props.componentTalkToSales;
  const unitSelectRef = useRef(null); // Create a reference for the select element

  // Local state to handle the floor divs
  const [floorDivs, setFloorDivs] = useState([]);
  const [showPulseEffect, setShowPulseEffect] = useState(true); // State for pulse effect on "+"
  const [showAreaPulseEffect, setShowAreaPulseEffect] = useState(true); // State for pulse effect on area input

  // Handle the change in area input value
  const handleAreaChange = (e) => {
    const value = e.target.value;
    const onlyNums = value.replace(/^0+|[^0-9]/g, ""); // Remove any leading zeros and non-numeric characters
    setFormData({ ...formData, area: { ...formData.area, value: onlyNums } });
    setShowAreaPulseEffect(false); // Remove pulse effect after first input
  };

  // Handle the change in area unit
  const handleUnitChange = (e) => {
    setFormData({ ...formData, area: { ...formData.area, unit: e.target.value } });
  };

  // Handle incrementing the number of floors
  const handleFloorIncrement = () => {
    setFormData({ ...formData, floor: parseInt(formData.floor, 10) + 1 });
    // Open the unit select dropdown
    if (unitSelectRef.current) {
      unitSelectRef.current.focus();
      unitSelectRef.current.click();
    }
    setShowPulseEffect(false); // Remove pulse effect after first click
  };

  // Handle decrementing the number of floors
  const handleFloorDecrement = () => {
    if (formData.floor > 1) {
      setFormData({ ...formData, floor: parseInt(formData.floor, 10) - 1 });
    }
  };

  useEffect(() => {
    // Update the floor divs based on the value of formData.floor
    const floors = parseInt(formData.floor, 10) || 0;
    setFloorDivs(
      Array.from({ length: floors }, (_, index) => (
        <motion.div
          key={index}
          className="floor-div new-floor"
          initial={{ opacity: 0, transform: "translateY(20px) rotateX(51deg) rotateZ(43deg)" }}
          animate={{ opacity: 1, transform: "translateY(0) rotateX(51deg) rotateZ(43deg)" }}
          transition={{ duration: 0.5, delay: 0.1 * index }}
        ></motion.div>
      ))
    );
  }, [formData.floor]);

  const handleNextClick = () => {
    const area = Number(formData.area.value);
    const areaInSqft = formData.area.unit === 'm2' ? area * 10.764 : area; // Convert to sqft if needed

    if (
      (areaInSqft > 60000 &&
        (formData.buildingType === "Residential" ||
          formData.buildingType === "Labs" ||
          formData.buildingType === "Manufacturing")) ||
      (areaInSqft > 100000 &&
        (formData.buildingType === "Office" ||
          formData.buildingType === "Hospitality")) ||
      (areaInSqft > 120000 && formData.buildingType === "Commercial") ||
      (areaInSqft > 200000 && formData.buildingType === "Warehouse")
    ) {
      console.log("a ventas");
      props.makeVisible(componentTalkToSales, "button4"); // Navigate to componentTalkToSales
    } else {
      props.makeVisible(componentNext, "button5"); // Otherwise, continue to componentNext
    }
  };

  const maxDivSize = 150; // Maximum div size in pixels
  const minDivSize = 60; // Minimum div size in pixels
  const divSize = Math.min(
    maxDivSize,
    minDivSize + (formData.area.value / 1000) * (maxDivSize - minDivSize)
  );

  return (
    <motion.section
      className="relative flex flex-col h-full overflow-hidden lg:bg-none backdrop-blur"
      id="plan"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* Background container */}
      <div className="absolute inset-0 bg-sidebarLight dark:bg-sidebarBlack lg:hidden z-0 bg-cover bg-center backdrop-blur"></div>
      <form className="p-5 h-full z-20" name="multi-step-form">
        <h1 className="text-2xl font-bold text-center dark:text-slate-300">
          Total Area & Floors
        </h1>
        <div className="max-w-xl mx-auto rounded-lg p-2">
          <Divider className="dark:text-slate-400 mt-2">
            Total area:
          </Divider>
          <div className="area-container flex flex-row justify-center items-center ">
            <div className="flex w:1/2 h-full w-full justify-center items-center">
              <motion.div
                className="area-div text-center text-white flex items-center justify-center"
                style={{ width: `${divSize}px`, height: `${divSize}px` }}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                {formData.area.value} {formData.area.unit}
              </motion.div>
            </div>
            <div className="flex w:1/2 h-full w-full justify-center items-center space-x-2">
              <motion.input
                type="number"
                className={`area-input ${showAreaPulseEffect ? "pulse-effect" : ""}`}
                id="area"
                name="area"
                value={formData.area.value}
                onChange={handleAreaChange}
                placeholder="Enter area"
                required
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              />
              <div className="relative inline-block">
                <select
                  className="area-unit-select"
                  value={formData.area.unit}
                  onChange={handleUnitChange}
                  ref={unitSelectRef} // Attach the ref to the select element
                >
                  <option value="sqft">sqft </option>
                  <option value="m2">m²  </option>
                </select>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="absolute top-1/2 right-[-19px] transform -translate-y-1/2 pointer-events-none text-black dark:text-white"
                />
              </div>
            </div>
          </div>
          <Divider className="dark:text-slate-400 mt-0">
            Select the number of floors:
          </Divider>
          <div className="flex flex-row ">
            <div className="flex flex-1 items-center justify-center">
              <p className="w-16 text-6xl text-center dark:text-slate-300">
                {formData.floor}
              </p>
              <div className="flex flex-col ml-4 space-y-2">
                <motion.button
                  type="button"
                  className={`p-2 bg-[#CDCCD4] dark:bg-[#1F2937] border-solid dark:border dark:border-slate-500 dark:text-white rounded-full hover:bg-[#75FBA6] ${
                    showPulseEffect ? "pulse-effect" : ""
                  }`}
                  onClick={handleFloorIncrement}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  +
                </motion.button>
                <motion.button
                  type="button"
                  className={`p-2 bg-[#CDCCD4] dark:bg-[#1F2937] rounded-full hover:bg-[#75FBA6] dark:border dark:border-slate-500 dark:text-white ${
                    formData.floor > 1 ? "visible" : "invisible"
                  }`}
                  onClick={handleFloorDecrement}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  -
                </motion.button>
              </div>
            </div>
            <div className="flex flex-1 floor-div-container">{floorDivs}</div>
          </div>
        </div>
      </form>
    </motion.section>
  );
}

export default Floor;
