import React from "react";
import { Link } from "react-router-dom";

// Componente principal Home
function Home() {
  return (
    <div className="flex flex-col items-center h-screen bg-white-200 dark:bg-gray-900">
      {/* Logo */}
      <div className="mb-8 mt-8">
        <div className="h-40 w-80 bg-logoLight dark:bg-logoDark bg-no-repeat bg-center bg-contain"></div>
      </div>
      {/* Botón LSS */}
      <div className="relative">
        <Link to="/lss">
          <button class="rounded-md bg-white px-3.5 py-2 text-md font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-32">
            LSS
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Home;
