import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "sonner";
import { format } from "date-fns";
import { motion } from "framer-motion";

// Internal Modules
import { useForm } from "../FormContext/FormContext";
import { validateFinalForm } from "./utils/validateFinalForm";
import { DateRangePicker, Divider } from "@tremor/react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "./Total.css";

// Register FilePond plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function Total(props) {
  const { formData, setFormData } = useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [acceptEmails, setAcceptEmails] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [checkboxLoading, setCheckboxLoading] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const componentBack = props.componentNameBack;
  const [isThankYouVisible, setIsThankYouVisible] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [files, setFiles] = useState([]); // State for FilePond files

  useEffect(() => {
    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
    const updateDarkMode = (e) => {
      if (e.matches) {
        document.body.classList.add("dark-mode");
      } else {
        document.body.classList.remove("dark-mode");
      }
    };

    updateDarkMode(matchMedia);
    matchMedia.addEventListener("change", updateDarkMode);

    return () => matchMedia.removeEventListener("change", updateDarkMode);
  }, []);

  const sendDataToBackend = async () => {
    const errors = validateFinalForm(formData);

    if (errors.length > 0) {
      errors.forEach((error) => toast.error(error));
      return;
    }

    setIsSpinning(true);

    // Prepare FormData
    const formDataToSend = new FormData();
    formDataToSend.append('data', JSON.stringify(formData));
    
    // Append files
    files.forEach((file, index) => {
      formDataToSend.append('files', file);
    });

    setTimeout(async () => {
      const promise = axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/area/lss`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.promise(promise, {
        loading: "Processing information...",
        success: (data) => {
          setIsButtonDisabled(false);
          setTimeout(() => {
            setIsSpinning(false);
            setIsThankYouVisible(true); // Show ThankYou component
          }, 1000);
          return `Data sent successfully!`;
        },
        error: (error) => {
          setIsButtonDisabled(false);
          setIsSpinning(false);
          console.log(error);
          return `Error, contact sales. `;
        },
      });
    }, 1000);
  };

  const handleDateRangeChange = (range) => {
    const formattedRange = {
      from: range.from,
      to: range.to,
    };
    setFormData((prevData) => ({ ...prevData, dateRange: formattedRange }));
  };

  const formatDate = (date) => {
    if (!date) return "N/A";
    return format(new Date(date), "dd/MM");
  };

  const handleCheckboxChange = async (event) => {
    setCheckboxLoading(true);
    setAcceptEmails(event.target.checked);
    if (event.target.checked) {
      setLoadingSpinner(true);
      await sendDataToBackend();
    }
    setCheckboxLoading(false);
  };

  const handleConfirmClick = () => {
    const errors = validateFinalForm(formData);

    if (errors.length > 0) {
      errors.forEach((error) => toast.error(error));
      return;
    }

    setShowCheckbox(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    console.log(`Field updated: ${name} = ${value}`);
  };

  if (isThankYouVisible) {
    const ThankYou = require("../ThankYou/ThankYou").default;
    return <ThankYou />;
  }

  return (
    <section className="flex flex-col items-center h-full p-4 relative" id="plan">
      <h1 className="text-2xl font-bold text-center dark:text-slate-300 mb-4">
        Summary
      </h1>

      <form className="w-full max-w-xl " name="multi-step-form">
        <Divider className="dark:text-slate-400 max-w-lg mt-1">
          Please provide your details to receive the estimate
        </Divider>
        <input
          type="text"
          className="block w-full mb-2 p-2 dark:bg-gray-800 dark:text-white border border-gray-300 dark:border-gray-700 rounded"
          id="name"
          name="name"
          placeholder="Your Name"
          value={formData.name || ""}
          onChange={handleInputChange}
        />
        <input
          type="text"
          className="block w-full mb-2 p-2 dark:bg-gray-800 dark:text-white border border-gray-300 dark:border-gray-700 rounded"
          id="company"
          name="company"
          placeholder="Company Name"
          value={formData.company || ""}
          onChange={handleInputChange}
        />
        <input
          type="email"
          className="block w-full mb-2 p-2 dark:bg-gray-800 dark:text-white border border-gray-300 dark:border-gray-700 rounded"
          id="email"
          name="mail"
          placeholder="email@email.com"
          value={formData.mail || ""}
          onChange={handleInputChange}
        />
        <input
          type="tel"
          className="block w-full mb-2 p-2 dark:bg-gray-800 dark:text-white border border-gray-300 dark:border-gray-700 rounded"
          id="phone"
          name="phone"
          placeholder="Your Phone Number"
          value={formData.phone || ""}
          onChange={handleInputChange}
        />
        <Divider className="dark:text-slate-400 max-w-lg">
          (*) Optional: Select estimated dates for scanning
        </Divider>
        <DateRangePicker
          className="w-full mx-auto dark:bg-gray-800 dark:text-white"
          minDate={new Date()} // Ensure only dates greater than the current date can be selected
          enableSelect={false}
          onValueChange={handleDateRangeChange}
        />
      </form>
      <Divider className="dark:text-slate-400 max-w-lg">
        (*) Optional: Attach images or blueprint
      </Divider>
      <div className="w-full max-w-xl mt-4">
        <FilePond
          files={files}
          onupdatefiles={(fileItems) => {
            // Update FilePond files state
            const updatedFiles = fileItems.map(fileItem => fileItem.file);
            setFiles(updatedFiles);
            console.log('Files updated:', updatedFiles);
          }}
          allowMultiple={true}
          maxFiles={3}
          name="files"
          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          instantUpload={false} // Disable instant upload
          className="filepond"
        />
      </div>

      <div className="flex flex-col sm:flex-row justify-between items-center mt-auto w-full relative">
        {!showCheckbox && (
          <>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="w-full sm:w-auto mb-2 sm:mb-0 p-2 md:p-3 bg-violet-500 text-white rounded-lg"
              name="Back"
              id="back4"
              onClick={() => props.makeVisible(componentBack, "button4")}
            >
              Go Back
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className={`w-full pd-2 sm:w-auto p-2 ${
                isButtonDisabled ? "bg-gray-400" : "bg-blue-500"
              } text-white rounded flex justify-center items-center transition-all duration-300 ease-in-out ${
                isSpinning ? "w-full " : "sm:w-auto  p-3"
              } ${showCheckbox ? "expand" : ""}`}
              onClick={handleConfirmClick}
              disabled={isButtonDisabled}
            >
              {isSpinning ? (
                <>
                  <div className="spinner mr-2"></div>
                  Processing...
                </>
              ) : (
                "Confirm"
              )}
            </motion.button>
          </>
        )}
      </div>
      {loadingSpinner && (
          <div className="spinner-container">
            <div className="spinner"></div>
            <span>Loading...</span>
          </div>
        )}
      {showCheckbox && !loadingSpinner && (
          <div className="spinner-container ">
            <div className="checkbox-container show ">
              <motion.input
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                type="checkbox"
                id="acceptEmails"
                name="acceptEmails"
                checked={acceptEmails}
                onChange={handleCheckboxChange}
                className="form-checkbox h-5 w-5 text-green-500"
              />
              <label
                htmlFor="acceptEmails"
                className="p-2 text-xs	"
              >
                Accept to receive information by email
              </label>
            </div>
          </div>
        )}
    </section>
  );
}

export default Total;
