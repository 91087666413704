// src/data/countryData.js

export const countriesByRegion = {
  northamerica: [
    "United States", "Canada", "Mexico", "Guatemala", "Honduras", "Panama", "Jamaica"
  ],
  latam: [
    "Argentina", "Brazil", "Chile", "Colombia", "Peru", "Uruguay", "Bolivia", "Ecuador"
  ],
  emea: [
    "Germany", "France", "United Kingdom", "Italy", "Spain", "Netherlands", "Sweden",
  ],
  others: [
    "Australia", "Japan", "South Korea", "New Zealand", "Singapore", "Thailand", "India", "China"
  ],
};

export const statesByCountry = {
  "United States": [
    "California", "New York", "Texas", "Florida", "Illinois", "Pennsylvania", "Ohio", "Georgia",
    "North Carolina", "Michigan", "New Jersey", "Virginia", "Washington", "Arizona", "Massachusetts",
    "Tennessee", "Indiana", "Missouri", "Maryland", "Wisconsin", "Colorado", "Minnesota", "South Carolina",
    "Alabama", "Louisiana", "Kentucky", "Oregon", "Oklahoma", "Connecticut", "Iowa", "Mississippi",
    "Arkansas", "Utah", "Nevada", "New Mexico", "West Virginia", "Nebraska", "Idaho", "Hawaii",
    "Maine", "New Hampshire", "Montana", "Rhode Island", "Delaware", "South Dakota", "North Dakota",
    "Alaska", "Vermont", "Wyoming"
  ],
  Canada: [
    "Ontario", "Quebec", "British Columbia", "Alberta", "Manitoba", "Saskatchewan", "Nova Scotia", "Newfoundland and Labrador"
  ],
  Mexico: [
    "Mexico City", "Jalisco", "Nuevo León", "Puebla", "Guanajuato", "Sonora", "Chiapas", "Oaxaca"
  ],
  Guatemala: [
    "Guatemala City", "Escuintla", "Quetzaltenango", "San Marcos", "Solalá", "Chimaltenango", "Santa Rosa"
  ],
  Cuba: [
    "Havana", "Santiago de Cuba", "Camagüey", "Holguín", "Ciego de Ávila", "Pinar del Río", "Matanzas", "Villa Clara"
  ],
  Honduras: [
    "Tegucigalpa", "San Pedro Sula", "Choluteca", "La Ceiba", "El Progreso", "Comayagua", "Juticalpa", "Puerto Cortés"
  ],
  Panama: [
    "Panama City", "Colón", "David", "La Chorrera", "Santiago", "Penonomé", "Aguadulce", "Chitré"
  ],
  Jamaica: [
    "Kingston", "Montego Bay", "Ocho Rios", "Negril", "Mandeville", "Spanish Town", "Port Antonio", "Falmouth"
  ],
  Argentina: [
    "Buenos Aires", "Cordoba", "Rosario", "Mendoza", "La Plata", "San Juan", "Salta", "Tucumán"
  ],
  Brazil: [
    "São Paulo", "Rio de Janeiro", "Brasília", "Salvador", "Fortaleza", "Belo Horizonte", "Manaus", "Recife"
  ],
  Chile: [
    "Santiago", "Valparaíso", "Concepción", "La Serena", "Temuco", "Antofagasta", "Rancagua", "Iquique"
  ],
  Colombia: [
    "Bogotá", "Medellín", "Cali", "Barranquilla", "Cartagena", "Bucaramanga", "Pereira", "Cúcuta"
  ],
  Peru: [
    "Lima", "Arequipa", "Trujillo", "Chiclayo", "Piura", "Cusco", "Iquitos", "Huancayo"
  ],
  Uruguay: [
    "Montevideo", "Salto", "Paysandú", "Rivera", "Tacuarembó", "Maldonado", "Durazno", "Colonia"
  ],
  Bolivia: [
    "La Paz", "Santa Cruz", "Cochabamba", "Sucre", "Potosí", "Oruro", "Tarija", "Beni"
  ],
  Ecuador: [
    "Quito", "Guayaquil", "Cuenca", "Machala", "Loja", "Ambato", "Manta", "Durán"
  ],
  Germany: [
    "Berlin", "Hamburg", "Munich", "Cologne", "Frankfurt", "Stuttgart", "Düsseldorf", "Dortmund"
  ],
  France: [
    "Paris", "Marseille", "Lyon", "Toulouse", "Nice", "Nantes", "Strasbourg", "Montpellier"
  ],
  "United Kingdom": [
    "London", "Birmingham", "Manchester", "Glasgow", "Liverpool", "Edinburgh", "Leeds", "Bristol"
  ],
  Italy: [
    "Rome", "Milan", "Naples", "Turin", "Palermo", "Genoa", "Bologna", "Florence"
  ],
  Spain: [
    "Madrid", "Barcelona", "Valencia", "Seville", "Zaragoza", "Malaga", "Murcia", "Palma"
  ],
  Netherlands: [
    "Amsterdam", "Rotterdam", "The Hague", "Utrecht", "Eindhoven", "Groningen", "Maastricht", "Haarlem"
  ],
  Sweden: [
    "Stockholm", "Gothenburg", "Malmö", "Uppsala", "Västerås", "Örebro", "Linköping", "Helsingborg"
  ],
  Russia: [
    "Moscow", "Saint Petersburg", "Novosibirsk", "Yekaterinburg", "Nizhny Novgorod", "Kazan", "Chelyabinsk", "Omsk"
  ],
  Australia: [
    "Sydney", "Melbourne", "Brisbane", "Perth", "Adelaide", "Gold Coast", "Canberra", "Hobart"
  ],
  Japan: [
    "Tokyo", "Osaka", "Kyoto", "Nagoya", "Hiroshima", "Fukuoka", "Sapporo", "Kobe"
  ],
  "South Korea": [
    "Seoul", "Busan", "Incheon", "Gwangju", "Daejeon", "Ulsan", "Jeju", "Suwon"
  ],
  "New Zealand": [
    "Auckland", "Wellington", "Christchurch", "Hamilton", "Tauranga", "Dunedin", "Palmerston North", "Napier-Hastings"
  ],
  Singapore: [
    "Singapore City"
  ],
  Thailand: [
    "Bangkok", "Chiang Mai", "Phuket", "Pattaya", "Nakhon Ratchasima", "Khon Kaen", "Hat Yai", "Udon Thani"
  ],
  India: [
    "Delhi", "Mumbai", "Bangalore", "Hyderabad", "Chennai", "Kolkata", "Ahmedabad", "Pune"
  ],
  China: [
    "Beijing", "Shanghai", "Guangzhou", "Shenzhen", "Chengdu", "Hong Kong", "Tianjin", "Wuhan"
  ],
};

