import React, { useState, useEffect } from "react";
import { useForm } from "../FormContext/FormContext"; // Import the context
import { Divider, Button, Dialog, DialogPanel } from "@tremor/react";
import ToggleSwitch from "./ToggleSwitch"; // Import ToggleSwitch
import { RiQuestionLine } from "@remixicon/react";
import ReactCompareImage from "react-compare-image";
import { motion } from "framer-motion";

// Import images
import colorImage from "./img/color.png";
import blackAndWhiteImage from "./img/whiteandblack.png";

function Color(props) {
  const { formData, setFormData } = useForm(); // Get the context
  const [backgroundClass, setBackgroundClass] = useState(
    "bg-blackandWhiteLight dark:bg-blackandWhiteDark"
  );
  const [dialogContent, setDialogContent] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [showComparison, setShowComparison] = useState(true);

  // Function to open the dialog with specific content
  const openDialog = (content) => {
    setDialogContent(content);
    setIsOpen(true);
  };

  useEffect(() => {
    // Set default scan type to "blackandwhite" on component load
    setFormData({ ...formData, typeOfScan: "blackandwhite" });
  }, []);

  useEffect(() => {
    setBackgroundClass(
      formData.typeOfScan === "color"
        ? "bg-colorLight dark:bg-colorDark"
        : "bg-blackandWhiteLight dark:bg-blackandWhiteDark"
    );
    if (formData.typeOfScan === "color") {
      setShowComparison(false);
    }
  }, [formData.typeOfScan]);

  return (
    <motion.section
      className={`flex relative flex-col h-full max-w-xl mx-auto ${backgroundClass} bg-cover bg-center`}
      id="plan"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        static={true}
        className="z-[100]"
      >
        <DialogPanel className="max-w-xl">
          <div className="p-8 shadow-md rounded-md max-w-4xl mx-auto">
            <h2 className="text-3xl font-bold mb-6 text-gray-800 dark:text-gray-200">
              Color Information
            </h2>
            <Divider className="dark:text-slate-400">Color Scan</Divider>
            <p className="text-gray-700 dark:text-gray-300 mb-4 mt-4">
              Color scan captures images with full color information, allowing for detailed representation of different hues and shades. This type of scan is useful for applications where color differentiation is important, such as visual inspections or detailed surveys.
            </p>
            <Divider className="dark:text-slate-400">Black and White Scan</Divider>
            <p className="text-gray-700 dark:text-gray-300 mt-4">
              Black and white scan captures images in grayscale, meaning only shades of gray are represented. This type of scan is typically used for applications where color is not critical, and it can highlight contrasts and details in a simplified format.
            </p>
          </div>
          <Button
            variant="light"
            className="mx-auto flex items-center"
            onClick={() => setIsOpen(false)}
          >
            Close
          </Button>
        </DialogPanel>
      </Dialog>
      <form className="p-5 flex-grow" name="multi-step-form">
        <h1 className="text-2xl font-bold text-center dark:text-slate-300 ">
          Color
        </h1>
        <Divider className="dark:text-slate-400">
          Select scan type: color or black and white.
        </Divider>
        <button
          className="absolute top-12 right-0 flex items-center justify-center rounded-full border border-transparent bg-transparent px-2.5 py-2 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-400"
          onClick={(e) => {
            e.preventDefault();
            openDialog("Ceiling");
          }}
        >
          <RiQuestionLine size={20} />
        </button>
        <motion.div
          className="relative mt-10"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <ToggleSwitch
            id="color"
            checked={formData.typeOfScan === "color"}
            onChange={(e) => {
              console.log(
                "Color option selected:",
                e.target.checked ? "Color" : "Black and White"
              );
              setFormData({
                ...formData,
                typeOfScan: e.target.checked ? "color" : "blackandwhite",
              });
              if (e.target.checked) {
                setShowComparison(false);
              } else {
                setShowComparison(false);
              }
            }}
          />
        </motion.div>
        <motion.div
          className="mt-10 w-full h-fit bg-white "
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          {showComparison ? (
            <ReactCompareImage
              leftImage={blackAndWhiteImage}
              rightImage={colorImage}
              leftImageLabel="Black and White"
              rightImageLabel="Color"
            />
          ) : formData.typeOfScan === "color" ? (
            <img src={colorImage} alt="Color Scan" className="w-full h-auto" />
          ) : (
            <img
              src={blackAndWhiteImage}
              alt="Black and White Scan"
              className="w-full h-auto"
            />
          )}
        </motion.div>
      </form>
    </motion.section>
  );
}

export default Color;
