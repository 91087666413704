import React from "react";
import { Divider } from '@tremor/react';

function WorkingHoursInfo() {
  return (
    <div className="p-8 w-full  rounded-md  ">
      <h2 className="text-3xl font-bold mb-6 text-gray-800 dark:text-gray-200">
        Working Hours Information
      </h2>
      <Divider className="dark:text-slate-400">Working Hours</Divider>

      <p className="text-gray-700 dark:text-gray-300 mb-4">
        <strong>Working hours</strong> typically refer to the standard business hours when most operations are conducted, which are usually from 8:00 AM to 5:00 PM, Monday through Friday. During this time, staff are generally available, and normal operations occur.
      </p>
      <Divider className="dark:text-slate-400">Non-regular Working Hours</Divider>

      <p className="text-gray-700 dark:text-gray-300 mt-4">
        <strong>Non-regular working hours</strong> refer to times outside of the standard business hours. This includes evenings, weekends, and holidays when regular business operations are usually closed or less active. Scanning during these times may require special arrangements or additional approvals.
      </p>
    </div>
  );
}

export default WorkingHoursInfo;
