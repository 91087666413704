import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

// Hooks
import { useForm } from "../FormContext/FormContext";

// Internal Modules
import { Divider, Card, Switch } from "@tremor/react";
import { ReactSVG } from "react-svg";

// Utils
import map from "./components/svg/map.svg";
import { countriesByRegion, statesByCountry } from "./components/countryData";
import "./spinner.css";

function Info(props) {
  const { formData, setFormData } = useForm();

  const [selectedRegion, setSelectedRegion] = useState(formData.region || "");
  const [selectedCountry, setSelectedCountry] = useState(formData.country || "");
  const [selectedState, setSelectedState] = useState(formData.state || "");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  // State for the switches
  const [isScanToBim, setIsScanToBim] = useState(formData.purpose === "scanToBim");
  const [isOnlyScan, setIsOnlyScan] = useState(formData.purpose === "onlyScan");

  // States for pulse effects
  const [showRegionPulse, setShowRegionPulse] = useState(true);
  const [showCountryPulse, setShowCountryPulse] = useState(false);
  const [showStatePulse, setShowStatePulse] = useState(false);

  // State for SVG loading
  const [svgLoaded, setSvgLoaded] = useState(false);

  // Effect to update the list of countries when the region changes
  useEffect(() => {
    const countriesList = countriesByRegion[selectedRegion] || [];
    setCountries(countriesList);
    if (!countriesList.includes(selectedCountry)) {
      setSelectedCountry(""); // Reset country if it's not in the new region
    }
    setStates([]); // Clear states when region changes

    if (selectedRegion) {
      setShowRegionPulse(false);
      setShowCountryPulse(true);
    }
  }, [selectedRegion, selectedCountry]);

  // Effect to update the list of states when the country changes
  useEffect(() => {
    const statesList = statesByCountry[selectedCountry] || [];
    setStates(statesList);
    if (!statesList.includes(selectedState)) {
      setSelectedState(""); // Reset state if it's not in the new country
    }

    if (selectedCountry) {
      setShowCountryPulse(false);
      setShowStatePulse(true);
    }
  }, [selectedCountry, selectedState]);

  // Handle switch change for Scan to BIM
  const handleScanToBimChange = (value) => {
    setIsScanToBim(value);
    if (value) {
      setFormData({ ...formData, purpose: "scanToBim" });
      setIsOnlyScan(false); // Ensure only one switch is selected at a time
    } else {
      setFormData({ ...formData, purpose: "" });
    }
  };

  // Handle switch change for Only Scan
  const handleOnlyScanChange = (value) => {
    setIsOnlyScan(value);
    if (value) {
      setFormData({ ...formData, purpose: "onlyScan" });
      setIsScanToBim(false); // Ensure only one switch is selected at a time
    } else {
      setFormData({ ...formData, purpose: "" });
    }
  };

  return (
    <motion.section 
      className="relative flex flex-col h-full overflow-hidden" 
      id="plan"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* Div container for backgrounds */}
      <div className="absolute inset-0 bg-sidebarLight dark:bg-sidebarBlack lg:hidden z-0 bg-cover bg-center backdrop-blur"></div>

      <form className="relative p-5 h-full overflow-auto z-10 md:mt-2" name="multi-step-form">
        <motion.h1 
          className="text-2xl font-bold text-center dark:text-slate-300 overflow-hidden mt-12 md:mt-2"
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          Project Region
        </motion.h1>
        <div className="max-w-xl mx-auto rounded-lg">
          <div className="w-full flex justify-center">
            <Divider className="dark:text-slate-400 mt-3">Region:</Divider>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-col">
              {!svgLoaded && (
                <div className="spinner1-container">
                  <div className="spinner1"></div>
                  <span>Loading...</span>
                </div>
              )}
              <ReactSVG
                src={map}
                className={`w-64 z-30 ${svgLoaded ? "block" : "hidden"}`}
                afterInjection={() => setSvgLoaded(true)}
              />
            </div>
            <div className="w-full max-w-sm flex flex-col">
              <motion.select
                value={selectedRegion}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSelectedRegion(newValue);
                  setFormData({ ...formData, region: newValue, country: "", state: "" });
                }}
                id="region"
                name="region"
                className={`dark:text-slate-300 custom-scrollbar w-64 mx-auto sm:w-full p-2 border rounded dark:bg-[#111827] ${showRegionPulse ? "pulse-effect-light" : ""}`}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                will-change="transform, opacity"
              >
                <option value="" disabled>Select the region</option>
                <option value="northamerica">NORTH AMERICA</option>
                <option value="latam">LATAM</option>
                <option value="emea">EMEA</option>
                <option value="others">OTHER</option>
              </motion.select>

              {selectedRegion && (
                <div className="flex flex-col gap-4 mt-4">
                  <motion.select
                    value={selectedCountry}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setSelectedCountry(newValue);
                      setFormData({ ...formData, country: newValue, state: "" });
                    }}
                    id="country"
                    name="country"
                    className={`dark:text-slate-300 custom-scrollbar w-64 mx-auto sm:w-full p-2 border rounded dark:bg-[#111827] ${showCountryPulse ? "pulse-effect-light" : ""}`}
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    will-change="transform, opacity"
                  >
                    <option value="" disabled>Select the country</option>
                    {countries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </motion.select>

                  {selectedCountry && (
                    <motion.select
                      value={selectedState}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setSelectedState(newValue);
                        setFormData({ ...formData, state: newValue });
                      }}
                      id="state"
                      name="state"
                      className={`dark:text-slate-300 custom-scrollbar w-64 mx-auto sm:w-full p-2 border rounded dark:bg-[#111827] ${showStatePulse ? "pulse-effect-light" : ""}`}
                      initial={{ x: -20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.5 }}
                      will-change="transform, opacity"
                      required
                    >
                      <option value="" disabled>Select the state</option>
                      {states.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </motion.select>
                  )}
                </div>
              )}

              <Divider className="dark:text-slate-400 mt-5">Purpose of the scan:</Divider>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Card className="mt-4 border dark:border-gray-600">
                  <div className="flex items-center justify-between">
                    <label htmlFor="scanToBim" className="dark:text-gray-300">
                      Scan to BIM
                    </label>
                    <Switch
                      id="scanToBim"
                      name="scanToBim"
                      checked={isScanToBim}
                      onChange={handleScanToBimChange}
                      color="violet-700"
                    />
                  </div>
                </Card>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <Card className="mt-4 border dark:border-gray-600">
                  <div className="flex items-center justify-between">
                    <label htmlFor="onlyScan" className="dark:text-gray-300">
                      Only Scan
                    </label>
                    <Switch
                      id="onlyScan"
                      name="onlyScan"
                      checked={isOnlyScan}
                      onChange={handleOnlyScanChange}
                      color="violet-700"
                    />
                  </div>
                </Card>
              </motion.div>
            </div>
          </div>
        </div>
      </form>
    </motion.section>
  );
}

export default Info;
