import React from 'react';
import thankYou from '../../../../assets/images/icon-thank-you.svg';

function ThankYou() {
    return (
        <section className="flex items-center justify-center mt-20 p-6" id="plan">
            <div className="p-6 text-center" name="multi-step-form">
                <img src={thankYou} alt="icon-thank-you" className="m-auto my-3 w-10" />
                <h1 className="text-3xl font-bold mb-3 dark:text-white">Thank you!</h1>
                <p className="text-gray-600 dark:text-white">Thanks for your submission!</p>
            </div>
        </section>
    );
}

export default ThankYou;
