// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 300px;
  border-radius: 8px;
}

.spinner-container1 .spinner1 {
  margin-right: 10px;
}

.spinner1 {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #22c55e;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Pulse effect */
.pulse-effect-light {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Areas/LSS/components/Area/spinner.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;EACpC,0BAA0B;EAC1B,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kCAAkC;AACpC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA,iBAAiB;AACjB;EACE,4BAA4B;AAC9B;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,YAAY;EACd;AACF","sourcesContent":[".spinner-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 60px;\n  width: 300px;\n  border-radius: 8px;\n}\n\n.spinner-container1 .spinner1 {\n  margin-right: 10px;\n}\n\n.spinner1 {\n  border: 4px solid rgba(0, 0, 0, 0.1);\n  border-left-color: #22c55e;\n  border-radius: 50%;\n  width: 24px;\n  height: 24px;\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n/* Pulse effect */\n.pulse-effect-light {\n  animation: pulse 2s infinite;\n}\n\n@keyframes pulse {\n  0%, 100% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
