import React, { useState, useEffect } from "react";
import Info from "./components/Area/Area";
import Building from "./components/BuildingType/BuildingType";
import Services from "./components/Services/Services";
import Color from "./components/Color/Color";
import Floor from "./components/Floor/Floor";
import Total from "./components/Total/Total";
import TalkToSales from "./components/TalkToSales/TalkToSales";
import ThankYou from "./components/ThankYou/ThankYou";
import { useForm } from "./components/FormContext/FormContext";
import "../../index.css";
import { toast } from 'sonner';
import { validateStep } from './utils/validations';

// Order of steps
const stepOrder = ["Info", "Building", "Services", "Color", "Floor", "Total"];

const LSS = () => {
  const [isVisible, setVisibility] = useState("Info");
  const [completedSteps, setCompletedSteps] = useState({
    Info: false,
    Building: false,
    Services: false,
    Color: false,
    Floor: false,
    Total: false
  });
  const [btnBg, setBtnBg] = useState({
    button1: { backgroundColor: "#75FBA6" },
    button2: { backgroundColor: "#4B00D2" },
    button3: { backgroundColor: "#4B00D2" },
    button4: { backgroundColor: "#4B00D2" },
    button5: { backgroundColor: "#4B00D2" },
    button6: { backgroundColor: "#4B00D2" },
  });

  const { formData } = useForm();

  const toggleVisibility = (componentName, buttonName, skipValidation = false) => {
    if (!skipValidation) {
      const currentStepValidation = validateStep(formData, isVisible);
      if (!currentStepValidation.isValid) {
        toast.error(currentStepValidation.message);
        return;
      }
    }

    const currentIndex = stepOrder.indexOf(isVisible);
    const nextIndex = stepOrder.indexOf(componentName);

    if (!skipValidation && nextIndex > currentIndex + 1) {
      toast.error("Please complete the previous steps before proceeding.");
      return;
    }

    setCompletedSteps((prev) => ({ ...prev, [isVisible]: true }));
    setVisibility(componentName);
    setBtnBg((prev) => ({
      ...Object.fromEntries(Object.keys(prev).map((name) => [name, { backgroundColor: "#4B00D2" }])),
      [buttonName]: { backgroundColor: "#75FBA6" },
    }));
  };

  const handleNavigationClick = (componentName, buttonName, isBack = false) => {
    toggleVisibility(componentName, buttonName, isBack);
  };

  const isSidebarDisabled = isVisible === "TalkToSales";

  return (
    <div className="flex flex-col justify-center items-center w-full h-full z-10 dark:bg-black bg-slate-50">
      <div className="shadow shadow-slate-400 border dark:border-slate-800 dark:shadow-md dark:shadow-gray-900 w-full h-full flex flex-col lg:flex-row lg:w-4/5 justify-start 
        lg:h-[87vh] lg:rounded-lg z-10 shadow-2xl dark:shadow-xl shadow-gray-300 dark:shadow-gray-900 dark:bg-gray-900 overflow-hidden">
        <Sidebar isSidebarDisabled={isSidebarDisabled} toggleVisibility={handleNavigationClick} btnBg={btnBg} completedSteps={completedSteps} />
        <Content isVisible={isVisible} toggleVisibility={handleNavigationClick} />
      </div>
    </div>
  );
};

const Sidebar = ({ isSidebarDisabled, toggleVisibility, btnBg, completedSteps }) => (
  <div
    className={`lg:bg-gray-100 lg:dark:bg-slate-800 dark:backdrop-blur-xl p-4 lg:w-1/4 w-full flex flex-col items-center lg:items-start justify-evenly lg:justify-start lg:rounded-xl z-10 ${isSidebarDisabled ? "opacity-50 pointer-events-none" : ""} lg:bg-sidebarLight lg:dark:bg-sidebarBlack bg-cover bg-center`}
  >
    {/* Logo visible solo en pantallas medianas o más grandes */}
    <div className="hidden lg:flex lg:mb-6 lg:mb-0 h-14 md:h-20 w-full bg-logoLight dark:bg-logoDark bg-no-repeat bg-center bg-contain"></div>
    <div className="flex lg:flex-col flex-row justify-center lg:justify-normal w-full overflow-x-auto">
      {stepOrder.map((step, index) => (
        <div key={index} className="flex flex-col lg:flex-row items-center content-around lg:items-center mt-2 lg:mt-4 sm:mt-2 w-full gap-3">
          <button
            className="btn-nav active:bg-blue-100 rounded-full w-8 h-8 md:w-12 md:h-12 flex items-center justify-center cursor-pointer text-white sm:text-sm md:text-base"
            type="button"
            name={`button${index + 1}`}
            onClick={() => toggleVisibility(step, `button${index + 1}`, true)}
            style={btnBg[`button${index + 1}`]}
            disabled={index > 0 && !completedSteps[stepOrder[index - 1]]}
          >
            {index + 1}
          </button>
          <div className="gr-navDiv flex  flex-col items-start ml-2 lg:ml-2">
            <p className="font-bold hidden lg:block dark:text-slate-300 sm:text-xs">Step {index + 1}</p>
            <h3 className="form-title dark:text-slate-400 font-mono text-xs lg:text-lg text-center sm:text-xs  lg:ml-0">{step}</h3>
          </div>
        </div>
      ))}
    </div>
    {/* Footer visible solo en pantallas medianas o más grandes */}
    <div className="hidden lg:flex footer flex-col items-center justify-end h-fit w-full mt-2 lg:mt-10">
  <p className="dark:text-gray-500 text-gray-400 text-xs lg:text-sm">Copyright © 2024 by VOYANSI.</p>
  <a 
    href="https://hexagon.com/legal/privacy-notice" 
    className="dark:text-gray-500 text-gray-400 text-xs" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    All rights reserved
  </a>
</div>

  </div>
);

const Content = ({ isVisible, toggleVisibility }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isVisible]);

  return (
    <div className="w-full h-full flex flex-col justify-between lg:ml-1/4 overflow-auto">
      <div className="overflow-y flex-grow">
        {isVisible === "Info" && <Info makeVisible={toggleVisibility} componentNameNext="Building" componentTalkToSales="TalkToSales" />}
        {isVisible === "Building" && <Building makeVisible={toggleVisibility} componentNameBack="Info" componentNameNext="Services" componentTalkToSales="TalkToSales" />}
        {isVisible === "Services" && <Services makeVisible={toggleVisibility} componentNameBack="Building" componentNameNext="Color" componentTalkToSales="TalkToSales" />}
        {isVisible === "Color" && <Color makeVisible={toggleVisibility} componentNameBack="Services" componentNameNext="Floor" componentTalkToSales="TalkToSales" />}
        {isVisible === "Floor" && <Floor makeVisible={toggleVisibility} componentNameBack="Color" componentNameNext="Total" componentTalkToSales="TalkToSales" />}
        {isVisible === "Total" && <Total makeVisible={toggleVisibility} componentNameBack="Floor" componentNameNext="ThankYou" />}
        {isVisible === "TalkToSales" && <TalkToSales makeVisible={toggleVisibility} componentNameBack="Info" componentNameNext="ThankYou" />}
        {isVisible === "ThankYou" && <ThankYou makeVisible={toggleVisibility} />}
      </div>
      {isVisible !== "Total" && isVisible !== "ThankYou" && (
        <NavigationButtons isVisible={isVisible} handleNavigationClick={toggleVisibility} />
      )}
    </div>
  );
};

const NavigationButtons = ({ isVisible, handleNavigationClick }) => (
  <div className="sticky bottom-0 w-full flex p-1 z-20 bg-opacity-20 backdrop-blur-md">
    {isVisible !== "Info" && (
      <input
        type="button"
        className="pd-2"
        name="Back"
        value="Go Back"
        onClick={() =>
          handleNavigationClick(
            {
              Building: "Info",
              Services: "Building",
              Color: "Services",
              Floor: "Color",
              Total: "Floor",
              ThankYou: "Total",
              TalkToSales: "Info",
            }[isVisible],
            {
              Building: "button1",
              Services: "button2",
              Color: "button3",
              Floor: "button4",
              Total: "button5",
              ThankYou: "button6",
              TalkToSales: "button1",
            }[isVisible],
            true // Indicates it's a backward movement
          )
        }
      />
    )}
    {isVisible !== "ThankYou" && (
      <input
        type="button"
        className="pd-2 ml-auto"
        name="Next"
        value="Next Step"
        onClick={() =>
          handleNavigationClick(
            {
              Info: "Building",
              Building: "Services",
              Services: "Color",
              Color: "Floor",
              Floor: "Total",
              Total: "ThankYou",
            }[isVisible],
            {
              Info: "button2",
              Building: "button3",
              Services: "button4",
              Color: "button5",
              Floor: "button6",
              Total: "button7",
            }[isVisible]
          )
        }
      />
    )}
  </div>
);

export default LSS;
