import React from "react";
import { useForm } from "../FormContext/FormContext";
import { Textarea } from '@tremor/react';

function TalkToSales(props) {
  const { formData, setFormData } = useForm();
  const componentBack = props.componentNameBack;

  // Función para determinar qué mensaje mostrar basado en las condiciones dadas
  const getMessage = () => {
    const { blackandwhite, belowCeiling, workingHours, buildingType, area,region } = formData;
    if (region === "others") {
      return "Para otras regiones debe contactarse con ventas, dejar un mensaje especificando";
    }
    if (blackandwhite === "false") {
      return "Para escaner a color debe contactarse con ventas, dejar un mensaje especificando";
    }

    if (belowCeiling === "false") {
      return "Para below ceiling debe contactarse con ventas, dejar un mensaje especificando";
    }

    if (workingHours === "false") {
      return "Para horas no laborales debe contactarse con ventas, dejar un mensaje especificando";
    }

    if (buildingType === "Other") {
      return "Para OTRO TIPO DE EDIFICIO debe contactarse con ventas, dejar un mensaje especificando";
    }

    const areaNumber = parseInt(area, 10);
    if (["Residential", "Labs", "Manufacturing"].includes(buildingType) && areaNumber > 60000) {
      return `Para ${buildingType} de más de ${area} debe contactarse con ventas, dejar un mensaje especificando`;
    }

    if (["Office", "Hospitality"].includes(buildingType) && areaNumber > 100000) {
      return `Para ${buildingType} de más de ${area} debe contactarse con ventas, dejar un mensaje especificando`;
    }

    if (buildingType === "Commercial" && areaNumber > 120000) {
      return `Para ${buildingType} de más de ${area} debe contactarse con ventas, dejar un mensaje especificando`;
    }

    if (buildingType === "Warehouse" && areaNumber > 200000) {
      return `Para ${buildingType} de más de ${area} debe contactarse con ventas, dejar un mensaje especificando`;
    }

    return null; // No se cumple ninguna condición
  };

  const message = getMessage(); // Obtenemos el mensaje basado en las condiciones

  return (
    <section className="flex flex-col h-full" id="plan">
      <form className="p-6 h-full max-w-xl mx-auto mt-2 md:mt-10 rounded-lg " name="multi-step-form">
      <h1 className="text-2xl font-bold text-center dark:text-slate-300">
      Region & Type of project</h1>
        <div className="max-w-sm mx-auto mt-6  rounded-lg  dark:text-slate-400">
          {message && <p>{message}</p>} {/* Mostramos el mensaje si existe */}
        </div>
        <div className="flex flex-col gap-2 mt-3">
      
          <Textarea
            // onChange={(e) => setValue(e.target.value)}
            id="description"
            rows={10}
            // value={value}
          />
</div>

      </form>
      <div className="flex flex-row justify-between p-4">
      <input
      type="button"
      className="pd-2"
      name="Back-3"
      id="back2"
      value="Go Back"
      onClick={() => props.makeVisible(componentBack, "button1")}
      />
        <input
          type="button"
          className="pd-2"
          id="next1"
          name="Next-2"
          value="Next Step"
          // onClick={() => props.makeVisible(componentNext, "button2")}
        />
      </div>
    </section>
  );
}
export default TalkToSales;
