import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Toaster, toast } from "sonner";

// import MEP from './Areas/MEP/MEP';
import Home from "./Areas/Home";
import LSS from "./Areas/LSS/LSS";
import { FormProvider } from "./Areas/LSS/components/FormContext/FormContext";

function App() {
  return (
    <FormProvider>
      <Router>
        <div className="App h-svh dark:bg-black bg-slate-50">
          {/* <div className="absolute  inset-0 bg-gradient-to-b from-green-400 to-blue-400 h-96  z-0"></div> */}

          <Toaster  position="top-center" theme={"system"} />

          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/MEP" element={<MEP />} /> */}
            <Route path="/LSS" element={<LSS />} />
          </Routes>

        </div>
      </Router>
    </FormProvider>
  );
}

export default App;
