import React from "react";
import { useForm } from "../FormContext/FormContext"; // Import FormContext to manage form data
import { motion } from "framer-motion";

// External Components
import { Divider, Button, Dialog, DialogPanel } from "@tremor/react";

// Internal Components
import CeilingInfo from "./components/CeilingInfo";
import WorkingHoursInfo from "./components/WorkingHoursInfo";

// Icons and SVGs
import { RiQuestionLine } from "@remixicon/react";
import { ReactSVG } from "react-svg";
import belowCeilingSVG from "./components/svg/belowCeiling.svg";
import aboveCeilingSVG from "./components/svg/aboveCeiling.svg";
import workingHourSVG from "./components/svg/workingHour.svg";
import notWorkingHourSVG from "./components/svg/notWorkingHour.svg";

// Main Service component
function Service(props) {
  const { formData, setFormData } = useForm(); // Get form data and setter from context
  const componentNext = props.componentNameNext;
  const componentBack = props.componentNameBack;
  const componentTalkToSales = props.componentTalkToSales; // Ensure this prop is passed to the component
  const [isOpen, setIsOpen] = React.useState(false);
  const [dialogContent, setDialogContent] = React.useState("");

  // Set initial form data if not already set
  React.useEffect(() => {
    if (!formData.Ceiling) {
      setFormData({ ...formData, Ceiling: "belowCeiling" });
    }
  }, [formData, setFormData]);

  // Modify handleNextClick to include navigation logic
  const handleNextClick = () => {
    if (
      formData.blackandwhite === "false" ||
      formData.Ceiling === "false" ||
      formData.workingHours === "false"
    ) {
      props.makeVisible(componentTalkToSales, "button4"); // Navigate to componentTalkToSales
    } else {
      props.makeVisible(componentNext, "button4"); // Navigate to componentNext
    }
  };

  // Function to open the dialog with specific content
  const openDialog = (content) => {
    setDialogContent(content);
    setIsOpen(true);
  };

  // Handler for ceiling checkboxes
  const handleCeilingChange = (type) => {
    if (type === "aboveCeiling") {
      if (formData.Ceiling === "belowCeiling") {
        setFormData({ ...formData, Ceiling: "both" });
      } else if (formData.Ceiling === "both") {
        setFormData({ ...formData, Ceiling: "belowCeiling" });
      } else {
        setFormData({ ...formData, Ceiling: "aboveCeiling" });
      }
    } else if (type === "belowCeiling") {
      if (formData.Ceiling === "aboveCeiling") {
        setFormData({ ...formData, Ceiling: "both" });
      } else if (formData.Ceiling === "both") {
        setFormData({ ...formData, Ceiling: "aboveCeiling" });
      } else {
        setFormData({ ...formData, Ceiling: "belowCeiling" });
      }
    }
  };

  return (
    <motion.section
      className="relative flex flex-col h-full overflow-hidden justify-center items-center p-2"
      id="plan"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* Background container */}
      <div className="absolute inset-0 bg-sidebarLight dark:bg-sidebarBlack lg:hidden z-0 bg-cover bg-center backdrop-blur"></div>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        static={true}
        className="z-[100]"
      >
        <DialogPanel className="max-w-xl">
          {dialogContent === "Ceiling" && <CeilingInfo />}
          {dialogContent === "Working Hours" && <WorkingHoursInfo />}
          <Button
            variant="light"
            className="mx-auto flex items-center"
            onClick={() => setIsOpen(false)}
          >
            Close
          </Button>
        </DialogPanel>
      </Dialog>

      <form className="w-full max-w-xl flex-grow z-20" name="multi-step-form">
        <h1 className="text-2xl font-bold text-center dark:text-slate-300">
          Service
        </h1>

        <div className="max-w-xl mx-auto">
          {/* Centering the content */}
          <motion.div
            className="flex flex-col relative"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <Divider className="dark:text-slate-400 mt-2">
              Select where the scan should be performed.
            </Divider>
            <button
              className="absolute top-5 right-0 flex items-center justify-center rounded-full border border-transparent bg-transparent px-2.5 py-2 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-400"
              onClick={(e) => {
                e.preventDefault();
                openDialog("Ceiling");
              }}
            >
              <RiQuestionLine size={20} />
            </button>
            <ul className="space-y-2 max-w-md mx-auto w-full p-1">
              {/* Centering the ul */}
              <li
                className="flex items-center w-full p-1 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-violet-500 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 max-w-lg"
                onClick={() => handleCeilingChange("belowCeiling")}
              >
                <input
                  type="checkbox"
                  id="below-ceiling"
                  value="belowCeiling"
                  className="mr-4 ml-5 w-8 h-8 text-violet-500 peer"
                  checked={formData.Ceiling === "belowCeiling" || formData.Ceiling === "both"}
                  onChange={() => handleCeilingChange("belowCeiling")}
                />
                <div className="flex flex-col items-center text-center flex-grow">
                  <ReactSVG
                    src={belowCeilingSVG}
                    className="w-32 h-20 filter dark:invert-0 invert"
                  />
                  <label
                    htmlFor="below-ceiling"
                    className="text-gray-500 dark:text-gray-300"
                  >
                    Below Ceiling
                  </label>
                </div>
              </li>
              <li
                className="flex items-center w-full p-1 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-violet-500 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 max-w-lg"
                onClick={() => handleCeilingChange("aboveCeiling")}
              >
                <input
                  type="checkbox"
                  id="above-ceiling"
                  value="aboveCeiling"
                  className="mr-4 ml-5 w-8 h-8 text-violet-500 peer"
                  checked={formData.Ceiling === "aboveCeiling" || formData.Ceiling === "both"}
                  onChange={() => handleCeilingChange("aboveCeiling")}
                />
                <div className="flex flex-col items-center text-center flex-grow">
                  <ReactSVG
                    src={aboveCeilingSVG}
                    className="w-32 h-20 filter dark:invert-0 invert"
                  />
                  <label
                    htmlFor="above-ceiling"
                    className="text-gray-500 dark:text-gray-300"
                  >
                    Above Ceiling
                  </label>
                </div>
              </li>
            </ul>
          </motion.div>

          <motion.div
            className="flex flex-col relative"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Divider className="dark:text-slate-400 mt-2">
              Select the time slot for the service
            </Divider>
            <button
              className="absolute top-5 right-0 flex items-center justify-center rounded-full border border-transparent bg-transparent px-2.5 py-2 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-400"
              onClick={(e) => {
                e.preventDefault();
                openDialog("Working Hours");
              }}
            >
              <RiQuestionLine size={20} />
            </button>
            <ul className="space-y-2 max-w-md mx-auto w-full p-1">
              {/* Centering the ul */}
              <li
                className="flex items-center w-full p-1 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-violet-500 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 max-w-lg"
                onClick={() => setFormData({ ...formData, workingHours: "true" })}
              >
                <input
                  type="checkbox"
                  id="working-hours"
                  value="true"
                  className="mr-4 ml-5 w-8 h-8 text-violet-500 peer"
                  checked={formData.workingHours === "true"}
                  onChange={() => setFormData({ ...formData, workingHours: "true" })}
                />
                <div className="flex flex-col items-center text-center flex-grow">
                  <ReactSVG
                    src={workingHourSVG}
                    className="w-32 h-20 filter dark:invert-0 invert"
                  />
                  <label
                    htmlFor="working-hours"
                    className="text-gray-500 dark:text-gray-300"
                  >
                    Working Hours
                  </label>
                </div>
              </li>
              <li
                className="flex items-center w-full p-1 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-violet-500 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 max-w-lg"
                onClick={() => setFormData({ ...formData, workingHours: "false" })}
              >
                <input
                  type="checkbox"
                  id="non-working-hours"
                  value="false"
                  className="mr-4 ml-5 w-8 h-8 text-violet-500 peer"
                  checked={formData.workingHours === "false"}
                  onChange={() => setFormData({ ...formData, workingHours: "false" })}
                />
                <div className="flex flex-col items-center text-center flex-grow">
                  <ReactSVG
                    src={notWorkingHourSVG}
                    className="w-32 h-20 filter dark:invert-0 invert"
                  />
                  <label
                    htmlFor="non-working-hours"
                    className="text-gray-500 dark:text-gray-300"
                  >
                    Non Regular Working Hours
                  </label>
                </div>
              </li>
            </ul>
          </motion.div>
        </div>
      </form>
    </motion.section>
  );
}

export default Service;
